export const ReqAddScreenDescription=(ws,screenID,lang,description)=>{
    let data ={
      request: "ReqAddScreenDescription", 
      requestID:1,
      screenID:screenID,
      langType: lang,
      description:description
    }
    try {
      console.log('ReqAddScreenDescription',data);
      
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqAddScreenDescription:'+ JSON.stringify(error)) // catch error
    }
  }