export const ReqMasterList=(ws,langType)=>{
    let data ={
      request: "ReqMasterList", 
      requestID:1,
      masterUserName:'UserA',
      langType: langType,
    }
    try {
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqMasterList ERROR:'+ JSON.stringify(error)) // catch error
    }
  }