export const ReqAddScreenCopy=(ws,screenName,folderID,screenID)=>{
    let data ={
      request: "ReqAddScreen", 
      screenName:screenName,
      folderID:folderID,
      requestID: 1,
      CopyFromUserName:"UserA",
      CopyFromScreenID:screenID
    }
    // if(screenID!==null)
    //     data.screenID=screenID
    if(folderID===null)
        delete data.folderID
    console.log(data)

    try {
        console.log('ReqAddScreenCopy',data);
        ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
        console.log(error)
        console.log('ReqAddScreen ERROR:'+ JSON.stringify(error)) // catch error
    }
  }