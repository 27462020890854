import React, { useState, useEffect, useRef } from 'react';
import {
    MaskIcon,
    Logo_W
} from '../icons';

const f10 = '10px';
const f12 = '12px';
const f15 = '15px';

export default function LoginPage(props) {
    const [isMask, setisMask] = useState(true);
    const handleMask = () => {
        setisMask(!isMask);
    };

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            props.handleLogin();
            // 在登录时（按下回车键），将用户名存储到本地存储
            localStorage.setItem('lastUsername', props.username);
        }
    };

 

    return (
        <div style={{ fontSize: 18, display: 'flex', justifyContent: 'center', width: '100vw', height: '100vh', boxSizing: 'border-box', fontSize: '18px', alignItems: 'center', backgroundImage: props.isMobile? `url("/login_m.png")` : `url("/login.png")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', color: 'white' }}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#718CA6B2', borderRadius: '15px', padding: '3vw', 'mixBlendMode': 'normal', 'backdropFilter': 'blur(10px)', boxSizing: 'border-box',...props.isMobile? { 'width': '95%', maxWidth: 500 } : {} }}>
                <div style={{ marginTop: '2.5vw', marginBottom: '2.5vw', width: '100%', textAlign: 'center' }}><Logo_W width={'80%'} /></div>

                <div style={{ position: 'relative', width: '100%' }}>
                    <input
                        onKeyDown={keyPress}
                        value={props.username}
                        onChange={props.handleUsername}
                        style={{ height: 52, width: props.isMobile? '100%' : 460, backgroundColor: 'transparent', border: '1px solid #FFFFFF50', borderRadius: 2, color: 'white', fontSize: '18px', paddingLeft: '20px', boxSizing: 'border-box' }}
                    />
                    <div style={{ position: 'absolute', fontSize: 14, top: -10, left: 10, backgroundColor: 'rgb(88, 112, 153)' }}>
                        Username
                    </div>
                </div>

                <div style={{ position: 'relative', width: '100%' }}>
                    <input
                        onKeyDown={keyPress}
                        value={props.password}
                        onChange={props.hanldePassword}
                        type={isMask? 'password' : 'text'}
                        style={{ marginTop: 30, height: 52, width: props.isMobile? '100%' : 460, backgroundColor: 'transparent', border: '1px solid #FFFFFF50', borderRadius: 2, color: 'white', fontSize: '18px', paddingLeft: '20px', paddingRight: '40px', boxSizing: 'border-box' }}
                    />
                    <div style={{ position: 'absolute', fontSize: 14, top: 20, left: 10, backgroundColor: 'rgb(88, 112, 153)' }}>
                        Password
                    </div>
                    <div onClick={handleMask} className='mousehover' style={{ position: 'absolute', fontSize: 14, top: 'calc( 50% + 9px)', right: 20, opacity: isMask? 1 :.3 }}>
                        <MaskIcon />
                    </div>
                </div>

                <div style={{ marginTop: 20, fontSize: f15, color: '#FF5858' }}>
                    {props.LoginErrMessage}
                </div>

                <div onClick={props.handleLogin(props.username)} className='mousehover' style={{ fontWeight: 500, width: '100%', height: '33.76px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#019BBD', marginTop: 20, borderRadius: 5, fontSize: 15 }}>
                    LOGIN
                </div>
                {/*<div className='mousehover' style={{ fontWeight: 500, width: '100%', height: '33.76px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#454F5B', marginTop: 15, borderRadius: 5, fontSize: 15 }}>
                    SIGNUP
                </div>*/}

                <div style={{ fontSize: 12, marginTop: '2vw', fontWeight: 500, width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                    <div className='mousehover' style={{}}>FORGOT PASSWORD?</div>

                    <div className='mousehover' style={{}}>Terms and Conditions</div>
                </div>

                <div style={{ width: '100%', minHeight: '5vh' }} />

                {/*<div  style={{ fontSize: 12, marginTop: '10vh', display: 'flex', flexDirection: 'row'}}>
                    <div className='mousehover' style={{ marginRight: '2vw'}}>Terms and Conditions</div>
                    <div className='mousehover' style={{ marginRight: '2vw'}}>FAQ</div>
                    <div className='mousehover' style={{ marginRight: '2vw'}}>Cookies</div>
                    <div className='mousehover' style={{ marginRight: '2vw'}}>About Us</div>
                </div>*/}
            </div>
        </div>
    );
}