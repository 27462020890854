import React,{useState,useEffect,useRef} from 'react';
import DropDownMenu from '../screens/components/DropDownMenu'
import lang from '../lang.json'
export default function DescriptionPopUp(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	const arr = [
        {value:L('English'),data:1},
        {value:'繁体中文',data:2},
        {value:'简体中文',data:3},
    ]
	const escKeyListenerRef = useRef(null);
    useEffect(() => {
        // 定义事件监听器回调函数
        escKeyListenerRef.current = (event) => {
            if (event.keyCode === 27) {
                props.handleCancel();
            }
        };
        // 添加keydown事件监听器到document对象
        document.addEventListener('keydown', escKeyListenerRef.current);
        // 在组件卸载时移除事件监听器
        return () => {
            document.removeEventListener('keydown', escKeyListenerRef.current);
        };
    }, [props.handleCancel]);
	return (
	  <div style={{zIndex:5,boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.2)',backdropFilter:"blur(4px)",display:'flex',justifyContent:'center',alignItems:'center'}}>
	    <div style={{boxSizing:"border-box",backgroundColor:props.theme?'#0F4E5C':'white',display:'flex',flexDirection:'column',padding:'20px',alignItems:'flex-start',width:'80vw',height:'80vh',maxWidth:'95%'}}>
        <iframe
          src={props.url}
          width="100%"
          height="100%">
        </iframe>
	      <div style={{boxSizing:"border-box",flex:1,width:'100%',display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'flex-start'}}>
	        <div onClick={props.handleCancel} className='mousehover' style={{backgroundColor:props.theme?'#2B5563':"#BABABA",color:'white',width:150,height:48,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px',marginTop:'10px'}}>
	          {L('Cancel')}
	        </div>
	      </div>
	    </div>
	  </div>
	)
}