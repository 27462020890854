import React,{useState,useEffect,useRef} from 'react';
import DropDownMenu from '../screens/components/DropDownMenu'
import lang from '../lang.json'
export default function CreateScreenPopUp(props){
	const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
	const getArray=()=>{
		let arr=[]
		for(let i in props.screenListFolder)
			if(!props.screenListFolder[i].notFolder)
				arr.push({value:props.screenListFolder[i].folderName,data:i})
		console.log('screenarr',arr);
		return arr
		
		
	}
console.log('props.screenListFolder',props.screenListFolder);

	return (
	  <div style={{zIndex:5,boxSizing:"border-box",position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.2)',backdropFilter:"blur(4px)",display:'flex',justifyContent:'center',alignItems:'center'}}>
	    <div style={{boxSizing:"border-box",backgroundColor:props.theme?'#0F4E5C':'white',display:'flex',flexDirection:'column',padding:'20px',alignItems:'flex-start',width:'500px',minHeight:'300px',maxWidth:'95%'}}>
	      <div style={{fontSize:"18px",fontWeight:700,lineHeight:'21px'}}>{L('Create New Screen')}</div>
	      <input onChange={props.handleInput} value={props.value} style={{boxSizing:"border-box",fontSize:'16px',backgroundColor:props.theme?'#1D353D':'#DDDDDD',border:'transparent',padding:'7px',color:props.theme?'white':"black",marginTop:'20px',width:'100%'}} placeholder={L('Please enter a name')}/>
	      <div style={{fontSize:'14px',color:props.theme?"#A0A0A0":'#787878',marginTop:"10px"}}>{L('*up to 50 characters')}</div>
	      
	      <div style={{marginTop:10,fontSize:'14px',color:props.theme?"#A0A0A0":'#787878',marginTop:"10px"}}>{L('Target Folder')}</div>
	      <DropDownMenu
	      	dropboxstyle={{backgroundColor:props.theme?'#081F26':'#DDDDDD'}}
            itemstyle={{backgroundColor:props.theme?'#081F26':'#DDDDDD',display:'flex',alignItems:'center',color:props.theme?'white':'black',border:'none',minHeight:26,paddingLeft:20}}
            onClick={props.handleSetnewScreenFolderIndex} 
            data={[{value:L('no folder'),data:null},...getArray()]} 
            value={props.newScreenFolderIndex===null?L('no folder'):props.screenListFolder[props.newScreenFolderIndex].folderName} 
            style={{backgroundColor:props.theme?'#143038':'#DDDDDD',width:'100%',marginTop:10,fontSize:14}}
	      />

	      <div style={{boxSizing:"border-box",flex:1,width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
	        <div onClick={props.handleCancel} className='mousehover' style={{backgroundColor:props.theme?'#2B5563':"#BABABA",color:'white',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
	          {L('Cancel')}
	        </div>
	        <div onClick={props.handleConfirm} className='mousehover' style={{marginLeft:20,backgroundColor:props.theme?'#043A4B':'#3A97F0',color:'white',width:91,height:24,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}}>
	          {L('Save')}
	        </div>
	      </div>
	    </div>
	  </div>
	)
}